import { Auth } from 'aws-amplify'
import { Camp } from '../components/SimSumHelpers'
import { graphql } from 'gatsby'
import Layout from '../layouts/layout'
import Masonry from 'react-masonry-css'
import React from 'react'
import SEO from '../components/seo'
import qs from 'qs'
import ribbon from '../images/ribbon.svg'
import shape1 from '../images/shape-1.svg'
import shape2 from '../images/shape-2.svg'
import shape3 from '../images/shape-3.svg'
import slugify from 'slugify'
import moment from 'moment'

const _ = require('lodash')

class CurationDetails extends React.PureComponent {
  render () {
    return (
      <a href={`/curated/${ slugify(this.props.curation.name, { replacement: '-', remove: /[^A-Za-z0-9 ]/g, lower: true }) }`}>
        <div className="camp card slideshow shadow-sm ss-4">
          <img className="card-img-top" src={ this.props.curation.indexImage.file.url } />
          <div className="card-footer text-center">
            <h6 className="m-0">{this.props.curation.name}</h6>
          </div>
        </div>
      </a>
    )
  }
}

class CampDetails extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    const campImage = (() => {
        if ( this.props.camp.image ) {
            return <img className="img-fluid rounded mb-3 shadow-sm border" src={ this.props.camp.image } alt="camp detail imagery" />
        }
    })()

    const daysTillNotFresh = 7;
    let newBadge;
    if (this.props.camp.updatedAt != undefined) {
        const freshness = moment().diff(moment(this.props.camp.updatedAt), "days") <= daysTillNotFresh;
        newBadge = freshness ? <><p className="new-camp">Added in the past {daysTillNotFresh} days</p></> : null;
    } else {
        newBadge = null;
    }


    return (
      <div className="camp card slideshow shadow-sm ss-4" key={ this.props.camp.id } id={ this.props.camp.id }>
        <a href={ this.props.camp.slug }>
          <div className="ribbon"><img src={ ribbon } /></div>
          <div className="card-body">
            { campImage }
            { newBadge }
            <h5 className="card-heading">{ this.props.camp.name }</h5>
            <h6 className="card-subheading">{ this.props.camp.organization.name }</h6>
            <div className="d-none d-lg-block">{ this.props.camp.summary }</div>
          </div>
          <div className="card-footer d-none d-lg-block">
            <div className="small font-italic summary">{ this.props.camp.snapshot() }</div>
          </div>
        </a>
      </div>
    )
  }
}

class PageContent extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      user: null,
      searchTerm: '',
      promotedCamps: [],
      exploreCamps: [],
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount = async () => {
    this.setState({ promotedCamps: _.sampleSize(this.props.data.allContentfulCamp.edges, 25) })
    this.setState({ exploreCamps: this.props.data.allContentfulCuratedListFeed.edges[0].node.curatedLists })
    await Auth.currentAuthenticatedUser()
      .then(response => {
        this.setState({ user: response })
      }).catch(err => {
        console.log(err)
        this.setState({ user: null })
      })
  }

  onSubmit (event) {
    event.preventDefault()
    window.location.assign(`/search/#${ qs.stringify({ term: this.state.searchTerm || undefined }) }`)
  }

  handleChange (event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  render () {
    const ctaButton = this.state.user != null ? (<a href="/search/"><button type="button" className='btn btn-cta'>Get Started</button></a>) : (<a href="/account/"><button type="button" className='btn btn-cta'>Sign In or Sign Up</button></a>)
    return (
      <Layout location={this.props.location}>
        <SEO title="Simple Summers Home" keywords={[`simple`]} />

        {/* intro */}
        <div className="container-fluid">
            <div className="container pb-5">
                <div className="row light-blue homepage-hero">
                    <div className="col-md-6 col-12">
                        <div className="homepage-hero--inner">
                            <h5>&#9728;SUMMER 2025 EDITION!&#9728;</h5>
                            <div className="pb-3">Simple Summers is the first and only platform that connects camps and parents — find, sort, filter, organize, & register (coming soon) for summer camps all in one place.<br/> <br/> The time to book your summer camps is now, today. Dive in.</div>
                            { ctaButton }
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid">
          <div className="container pb-5">
            <div className="row">
              <div className="col-12 text-center">
                <h2>We do the hard work.</h2>
              </div>
              <div className="col-12 col-sm-4 text-center">
                <div className="camp card slideshow shadow-sm ss-4">
                  <img className="card-img-top" src={ shape1 } />
                  <div className="card-footer text-center">
                    <p className="m-0">Thousands (and thousands) of camp sessions.</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-4 text-center">
                <div className="camp card slideshow shadow-sm ss-4">
                  <img className="card-img-top" src={ shape2 } />
                  <div className="card-footer text-center">
                    <p className="m-0">Simple searching, filtering, and saving of camps.</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-4 text-center">
                <div className="camp card slideshow shadow-sm ss-4">
                  <img className="card-img-top" src={ shape3 } />
                  <div className="card-footer text-center">
                    <p className="m-0">Build your camp schedule, then add to calendar.</p>
                  </div>
                </div>
              </div>
              <div className="col-12 text-center pt-3">
                { ctaButton }
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="container pb-5">
            <div className="row">
              <div className="col-12 text-center">
                <h2>Explore</h2>
                <div className="pb-3">Not sure what you want? Start with some of our curated lists. Note: As we add camps in 2025, we'll add more curated lists!</div>
              </div>
              {this.state.exploreCamps.map((curationData, index) => (<div className="col-sm-4 col-md-3 pb-3" key={index}><CurationDetails curation={curationData} /></div>))}
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="container pb-5">
            <div className="row">
              <div className="col-12 text-center">
                <h2>Featured Camps</h2>
                <div className="pb-3">Once we start adding 2025 camps, we'll add partners.</div>
              </div>
              <div className="col-12">
                <Masonry breakpointCols={{ default: 4, 992: 3, 768: 2, 576: 1 }} className="simsum-masonry-grid" columnClassName="simsum-masonry-grid-column">
                  {this.state.promotedCamps.map((campData, index) => (<CampDetails camp={ new Camp(campData.node) } key={index} />))}
                </Masonry>
              </div>
            </div>
          </div>
        </div>

      </Layout>
    )
  }
}

export default PageContent

export const query = graphql`
  {
    allContentfulCuratedListFeed(filter: {name: {eq: "Homepage"}}) {
      edges {
        node {
          id
          name
          curatedLists {
            id
            name
            indexImage {
              file {
                url
              }
            }
          }
        }
      }
    }
    allContentfulCamp (
      filter: {
        isFeatured: {eq: true}
      }
    ) {
      edges {
        node {
          contentful_id
          updatedAt
          name
          summary
          isFeatured
          organization {
            contentful_id
            name
            accountTier
          }
          images {
            file {
              url
            }
          }
          sessions {
            contentful_id
            name
            minGrade
            maxGrade
            minAge
            maxAge
            price
            startDate
            endDate
            address {
              location {
                lat
                lon
              }
              city
            }
          }
        }
      }
    }
  }
`
